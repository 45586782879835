
































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import PriceBreakDown from "../types";

@Component({
  components: {},
})
export default class Home extends Vue {
  rates: { [key: string]: number } = {
    USD: 95,
    GBP: 120,
    CUSTOM: 100,
  };

  units: { [key: string]: number } = {
    Grams: 1,
    KG: 1000,
    LBS: 453.593,
  };

  unit = "Grams";
  currency = "GBP";

  delivery = false;
  deliveryCost = 60;

  weight = 0;
  price = 0;
  totalPrice = 0;

  isTax = false;
  tax = 0;

  isVat = false;
  vat = 5;

  defaultRate = false;

  rate = this.rates[this.currency];

  get shipmentRate(): number {
    return this.units[this.unit];
  }

  get priceData(): PriceBreakDown | null {
    if (this.price) {
      const productPrice = this.price * this.rate;
      const tax = this.isTax ? this.tax * this.rate : 0;
      const shipment = this.shipmentRate * this.weight;
      const subtotal = productPrice + tax + shipment;
      const vat = this.isVat ? subtotal * (this.vat / 100) : 0;
      const delivery = this.delivery ? this.deliveryCost : 0;

      const price = subtotal + vat + delivery;
      return { productPrice, shipment, price, subtotal, delivery, vat, tax };
    } else {
      return null;
    }
  }

  @Watch("currency")
  ratesChange(val: any, oldVal: any) {
    if (val != oldVal) {
      this.rate = this.rates[val];
    }
  }
  @Watch("delivery")
  deliveryChange(val: boolean, oldVal: boolean) {
    if (val != oldVal) {
      if (val == false) {
        this.deliveryCost = 0;
      } else {
        this.deliveryCost = 60;
      }
    }
  }
}












import { Component, Vue } from "vue-property-decorator";
import NavBar from "@/components/layout/Navbar.vue";
import Footer from "@/components/layout/Footer.vue";
@Component({
  components: {
    NavBar,
    Footer,
  },
})
export default class App extends Vue {}
